
























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ProjectModule, Project } from '@/store/projects';
import { ApiHelper } from '@/ApiHelper';
import { AppModule } from '@/store/app';

@Component <ProjectsList> ({
  head: {
    title() {
      return { inner: this.documentTitle }
    },
    meta() {
      return [
        { property: 'keyword', content: ',なわてん,大阪電気通信大学,四條畷キャンパス,卒業研究,卒業制作展,総合情報学部,デジタルゲーム学科,情報学科,医療健康科学部', id: 'metakeyword'},
        { property: 'description', content: 'なわてん - 2024年度 大阪電気通信大学 四條畷キャンパス 卒業研究・卒業制作展 総合情報学部、デジタルゲーム学科、情報学科、医療健康科学部 2025年2月8日（土）、9日（日）', id: 'metadescription'},
        { property: 'og:title', content: this.documentTitle, id:'ogtitle' },
        { property: 'og:type', content: 'website', id: 'ogtype' },
        { property: 'og:description', content: 'なわてん - 2024年度 大阪電気通信大学 四條畷キャンパス 卒業研究・卒業制作展 総合情報学部、デジタルゲーム学科、情報学科、医療健康科学部 2025年2月8日（土）、9日（日）', id: 'ogdescription'},
        { property: 'og:url', content: document.URL, id: 'ogurl' },
        { property: 'og:image', content: document.URL + 'img/ogp.png', id:'ogimage' },
        { property: 'twitter:card', content: 'summary_large_image', id:'twittercard' },
        { property: 'twitter:site', content: '@nawaten_info', id: 'twittersite' },
      ];
    },
  },
})

export default class ProjectsList extends Vue {
  private routename = '';
  private eyecatch = '';
  private title = '';
  private documentTitle = '';
  private sections = [];
  private projects = [];

  private created() {
    AppModule.setLoaded(true);
  }
  private mounted(){
    this.handlePage();
  }

  private getThumbnail(project: any){
    if(project.acf.squareEyecatch){
      if(project.acf.squareEyecatch.sizes){
        return 'background-image: url('+project.acf.squareEyecatch.sizes.thumbnail+')';
      }
    }
    return "";
  }

  public handlePage() {

    ApiHelper.callApi(`wp-json/wp/v2/section/`, 'get', {}, res => {
      this.sections = res.data;
    }, err =>{
      console.log(err.response.data.message);
    });

    ApiHelper.callApi(`wp-json/wp/v2/types/project/`, 'get', {}, res => {
      this.title = res.data.name + '一覧';
      this.documentTitle = res.data.name + '一覧';
      this.$emit('updateHead');
    }, err =>{
      console.log(err.response.data.message);
    });

    ApiHelper.callApi(`wp-json/wp/v2/project?per_page=200&_fields=id,acf,title,section`, 'get', {}, res => {
      this.projects = res.data.sort((a: any, b: any)=>{ a.pageview - b.pageview});
    }, err =>{
      console.log(err.response.data.message);
    });
  }
}
